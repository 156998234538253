














































import { defineComponent } from "@vue/composition-api";
import { Button, Icon, List, PullRefresh, Search, Sticky, Image } from "vant";
import moment from "moment";
moment.locale("zh-cn");

interface CardShipOrder {
  userName: string;
  userPhone: string;
  userAvatar: string;
  stateMsg: string;
  state: number;
  createTime: string;
  handleTime: string;
}

export default defineComponent({
  components: {
    [Sticky.name]: Sticky,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Image.name]: Image
  },
  data() {
    return {
      keywordRaw: "",
      mode: 0,
      list: [
        {
          userName: "x",
          userPhone: "15700085332",
          createTime: "2021-05-01",
          handleTime: "2021-05-02",
          stateMsg: "待处理",
          state: 0
        },
        {
          userName: "x2",
          userPhone: "15700085332",
          createTime: "2021-05-02",
          handleTime: "2021-05-02",
          stateMsg: "已处理",
          state: 2
        }
      ] as CardShipOrder[],
      refreshing: false,
      loadFinished: false,
      empty: false
    };
  },
  methods: {
    search() {},
    onRefresh() {},
    onLoad() {},
    formatTime(time: number) {
      return moment(time).format("lll");
    }
  }
});
